import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Pagination from '@material-ui/lab/Pagination';
import { navigate } from 'gatsby';
import { useSelector } from 'react-redux';
import * as Api from '../../apis';

const useStyles = makeStyles(theme => ({
  blogButton: {
    height: 50,
    fontWeight: 'bold'
  },
  red: {
    color: 'red'
  },
  toggleButton: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    border: `1px solid ${theme.palette.primary.main}`,
    marginRight: 10,
    marginBottom: 10,
  },
  selectedButton: {
    color: 'white !important',
    background: 'red !important'
  },
  questionnaireContainer: {
    padding: 20
  },
  questionnaireButton: {
    background: 'linear-gradient(#0087ff, #8ec4fb)',
    color: 'white',
    width: '100%',
    fontWeight: 'bold',
    minHeight: 45,
    '&:active, &:hover': {
      background: 'linear-gradient(#0087ffa3, #8ec4fb)',
      color: 'white',
    },
  },
  quoteBox: {
    border: '2px solid #4677a8',
    width: 200,
    height: 50,
    background: 'white',
    position: 'fixed',
    bottom: 25,
    right: 60,
    cursor: 'pointer'
  },
  triangleRight: {
    bottom: 32,
    position: 'relative',
    left: 196,
    width: 0,
    height: 0,
    borderTop: '10px solid transparent',
    borderLeft: '54px solid #4677a8',
    borderBottom: '10px solid transparent',
  },
  innerTriangleRight: {
    width: 0,
    bottom: 50,
    position: 'relative',
    left: 196,
    height: 0,
    borderTop: '8px solid transparent',
    borderLeft: '46px solid white',
    borderBottom: '8px solid transparent',
  }
}));

const QuestionnaireList = ({ categoryId = 'all' }) => {
  const classes = useStyles();
  const categories = useSelector(state => state.questionnaire.categories);
  const [selectedCategory, setSelectedCategory] = useState(categoryId || 'all');
  const [questionnaires, setQuestionnaires] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    getQuestionnaires();
  }, []);

  useEffect(() => {
    getQuestionnaires();
  }, [page]);

  useEffect(() => {
    setPage(1);
    getQuestionnaires();
  }, [selectedCategory]);

  useEffect(() => {
    if (categories.length) {
      setCategoryList([{ name: 'ทั้งหมด', id: 'all' }, ...categories]);
    }
  }, [categories]);

  function getQuestionnaires() {
    const category = selectedCategory === 'all' ? '' : selectedCategory;
    Api.getQuestionnaires(category, page).then(({ results, count }) => {
      setQuestionnaires(results);
      setPageCount(Math.ceil(count / 10));
    });
  }

  return (
    <div>
      <Box display="flex" justifyContent="space-between" style={{ margin: '10px 0px' }}>
        <h3>ทำแบบประเมิน <span className={classes.red}>ฟรี!</span></h3>
      </Box>
      <div>
        {
          categoryList.map((category) => (
            <Button
              className={`${classes.toggleButton} ${selectedCategory.toString() === category.id.toString() ? classes.selectedButton : ''} ${selectedCategory}`}
              key={category.id}
              onClick={() => setSelectedCategory(category.id)}
              variant="outlined"
            >
              {category.name}
            </Button>
          ))
        }
      </div>
      <Grid container className={classes.questionnaireContainer} spacing={2}>
        {
          questionnaires.map((questionnaire) => (
            <Grid item xs={12} sm={6} key={questionnaire.id}>
              <Button
                className={classes.questionnaireButton}
                onClick={() => navigate(`/questionnaire/?questionnaireId=${questionnaire.id}`)}
                variant="contained"
              >
                {questionnaire.name}
              </Button>
            </Grid>
          ))
        }
      </Grid>
      {
        !!pageCount && (
          <Box display="flex" justifyContent="center" pb={2}>
            <Pagination
              count={pageCount}
              color="primary"
              page={page}
              onChange={(_, value) => setPage(value)}
            />
          </Box>
        )
      }
    </div>
  );
}

export default QuestionnaireList;
