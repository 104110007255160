import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    // maxWidth: 345,
  },
  media: {
    height: 160,
  },
  breakWord: {
    wordBreak: 'break-word'
  },
  title: {
    fontWeight: 'bold',
    fontSize: 18
  },
  multiLineEllipsis: {
    height: 40,
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical"
  },
});

export default function BlogCard({ image, title, detail, onClick }) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea onClick={onClick}>
        <CardMedia
          className={classes.media}
          image={image}
          title={title}
        />
        <CardContent>
          <Typography noWrap classes={{ root: `${classes.breakWord} ${classes.title}` }} gutterBottom variant="h6" component="h3">
            {title}
          </Typography>
          <Typography classes={{ root: `${classes.breakWord}  ${classes.multiLineEllipsis}` }} variant="body2" color="textSecondary" component="p">
            {detail}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}